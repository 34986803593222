import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('../src/components/Login.vue')
  },
  //ADMINISTRADOR
  {
    path: '/pagina-principal-administrador',
    component: () => import('../src/components/ArquivosFronts/PaginaPrincipal.vue'),
    meta: {
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router