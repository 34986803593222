import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/pt';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueTheMask from 'vue-the-mask'
import VueSession from 'vue-session'

var options = {
  persist: true
}

Vue.use(ElementUI, { locale });
Vue.use(ElementUI);
Vue.use(VueSession, options)
Vue.use(VueTheMask)
Vue.use(VueRouter);
Vue.use(Toast);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  el: '#app',
  router,
  components: { App }
}).$mount('#app')